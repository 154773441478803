import { Injectable } from '@angular/core';
import { InputDialogComponent } from '..//components/input-dialog.component';
import { PurchaseEntityInterface, PurchaseTypes } from '@proman/resources/purchase';
import { Customer, Material, PromanFile, Supplier } from '@proman/interfaces/entity-interfaces';
import { mapOptionTranslate } from '@proman/utils';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { Dialog } from './dialog.service';
import { PromanStateService } from './proman-state.service';
import { OcrEntityInterface } from '@proman/resources/ocr';
import { ToastService } from '@proman/services/toast.service';

@Injectable({ providedIn: 'root' })
export class OcrService {
  typeOptions: SelectOption[];
  purchaseEntityInterface: PurchaseEntityInterface;
  ocrEntityInterface: OcrEntityInterface;

  constructor(
    private Entity: Entity,
    private Filter: FilterService,
    private PromanState: PromanStateService,
    private Dialog: Dialog,
    private Toast: ToastService,
  ) {
    this.ocrEntityInterface = this.Entity.get('ocr');
    this.purchaseEntityInterface = this.Entity.get('purchase');
    this.purchaseEntityInterface.getPurchaseTypes().then((response: string[]) => this.typeOptions = response.map((item: string) => mapOptionTranslate(item, this.Filter.translate)));
  }

  invoiceAnalyze = () => {
    return this.Dialog.open(InputDialogComponent, {
      header: 'select_purchase_type',
      mainField: {
        name: 'purchase_type',
        key: 'type',
        type: 'select',
        config: { options: this.typeOptions }
      },
      parameters: [
        {
          name: 'files',
          key: 'files',
          type: 'files',
          config: { multiple: false }
        }
      ]
    }).then((response: { type: PurchaseTypes, files: PromanFile[] }) => {
      if (response && response.type && response.files && response.files[1]) {
        (response as any).files = response.files[1].id;
        this.ocrEntityInterface.analyzeInvoice({
          id: response.files,
          type: response.type
        }).then((analysisResponse: {
          supplier: Supplier,
          customer: Customer,
          date: string,
          invoiceNumber: string,
          items: Material[],
          type: string,
        }) => {
          const parameters = [];
          if (analysisResponse.supplier) parameters.push({
            name: 'supplier',
            key: 'supplier',
            type: 'autocomplete',
            config: { entity: 'supplier', key: 'id', autofocus: false },
            value: analysisResponse.supplier
          })

          if (analysisResponse.customer) parameters.push({
              name: 'customer',
              key: 'customer',
              type: 'autocomplete',
              config: { entity: 'customer', key: 'id', autofocus: false },
              value: analysisResponse.customer
            },
            {
              name: 'template',
              key: 'template',
              type: 'autocomplete',
              config: { entity: 'template', entityParams: { context: 'invoice' }, key: 'id', autofocus: false }
            });

          this.Dialog.open(InputDialogComponent, {
            header: 'verify_supplier',
            mainField: {
              name: 'items',
              key: 'items',
              type: 'list-manager',
              config: {
                template: 'analyzed_items_row',
                silentRemove: true,
                preventHandling: true,
                noModel: true,
                key: 'items',
                mainEntity: { items: analysisResponse.items },
                searchEntity: 'material'
              }
            },
            parameters
          }).then((verifiedResponse: { supplier: number, customer: number, template: number, [key: string]: any }) => {
            const itemsResponse: any[] = [];
            Object.entries(verifiedResponse).forEach(([key, value]) => {
              if (!['supplier', 'customer', 'template'].includes(key)) itemsResponse.push(value);
            });

            switch (analysisResponse.type) {
              case 'invoice':
                itemsResponse.forEach((item: any) => delete item.id);

                this.Entity.get('invoice').create({
                  customer: verifiedResponse.customer,
                  template: verifiedResponse.template,
                  records: itemsResponse,
                }).then((invoiceCreateResponse) => {
                  if (!invoiceCreateResponse) return;
                  this.PromanState.to('Invoice', invoiceCreateResponse.data);
                });
                break;

              default:
                this.purchaseEntityInterface.create({
                  type: response.type,
                  supplier: verifiedResponse.supplier
                }).then((purchaseResponse) => {
                  if (response.type === 'materials' || response.type === 'inventory') {
                    const entityInterface = this.Entity.get('material_quant') as any;
                    const promises: Promise<any>[] = [];
                    itemsResponse.map((item: any) => ({ ...item, material: item.id, vat: item.vat.id }));
                    if (response.type === 'materials') {
                      itemsResponse.forEach((item: any) => {
                        // eslint-disable-next-line no-prototype-builtins
                        if (item.hasOwnProperty('materialType')) {
                          promises.push(this.Entity.get('material').update({ id: item.material, materialType: item.materialType.id }));
                        }
                        delete item.materialType;
                      })
                    }

                    promises.push(entityInterface.purchase({
                      items: itemsResponse,
                      date: analysisResponse.date,
                      file: response.files,
                      invoiceNumber: analysisResponse.invoiceNumber,
                      purchase: purchaseResponse.data,
                      quantityType: 'quantity'
                    }));
                      Promise.all(promises).then((response) => {
                          if (!response || !response.length) return;
                          this.PromanState.to('Purchase', purchaseResponse.data);
                      });
                  } else {
                    const entityInterface = this.Entity.get('product') as any;
                    const promises: Promise<any>[] = [];
                    itemsResponse.map((item: any) => ({ ...item, product: item.id, vat: item.vat.id }));
                    promises.push(entityInterface.purchase_ocr({
                      items: itemsResponse,
                      date: analysisResponse.date,
                      file: response.files,
                      invoiceNumber: analysisResponse.invoiceNumber,
                      purchase: purchaseResponse.data,
                      quantityType: 'quantity'
                    }));
                    Promise.all(promises).then((response) => {
                      if (!response || !response.length) return;
                      this.PromanState.to('Purchase', response[0]);
                    });
                  }
                });
                break;
            }
          });
        }).catch((e) => this.Toast.pop('error', e.error?.error));
      }
    });
  };
}
